@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}





.leftimg_sec.animation2 {
  animation: shake 15s infinite;
}

.leftimg_sec.animation {
  animation: shake2 15s infinite;
}

.leftimg_sec.animation3 {
  animation: shake3 15s infinite;
}
@keyframes shake2 {

  0%,
  100% {
    transform: translateY(0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(-5px);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(5px);
  }
}

@keyframes shake3 {

  0%,
  100% {
    transform: translateY(0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(-3px);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(3px);
  }
}
@keyframes shake {

  0%,
  100% {
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-5px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(5px);
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-next:before, .slick-prev:before {
  font-family: slick;
  font-size: 30px;
  line-height: 1;
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-next:before, .slick-prev:before {
  color: #4d6998!important;
}
.abc::after{
  content: "☎️";
}